import type { RootState } from 'src/store/store';
import type { Brand } from 'src/interfaces/brand';

import { useDispatch, useSelector } from 'react-redux';

import { setBrand, updateBrand } from 'src/store/reducers/brandSlice';

export const useBrand = () => {
  const dispatch = useDispatch();
  const { brand, loading } = useSelector((state: RootState) => state.brand);

  const setBrandAction = (selectedBrand: Brand | null) => {
    dispatch(setBrand(selectedBrand));
  };

  const updateBrandAction = (updatedFields: Partial<Brand>) => {
    dispatch(updateBrand(updatedFields));
  };

  return {
    brand,
    loading,
    setBrand: setBrandAction,
    updateBrand: updateBrandAction,
  };
};
