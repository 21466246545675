import type { User } from 'src/interfaces/user';
import type { SnackbarMessage } from 'notistack';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { DocumentData } from 'firebase/firestore';
import type { SnackbarOptions } from 'src/components/shared/notifications/notification-snackbar';

import axios from 'axios';
import { doc, getDoc } from 'firebase/firestore';
import { put, call, takeLatest } from 'redux-saga/effects';

import { FIRESTORE } from 'src/lib/firebase';

import {
  triggerFetchUserSuccess,
  triggerFetchUserFailure,
  triggerFetchUserRequest,
  triggerVerifyEmailRequest,
} from '../reducers/userSlice';

function* triggerFetchUserSaga(action: PayloadAction<string>): Generator<any, void, DocumentData> {
  try {
    const userRef = doc(FIRESTORE, 'users', action.payload);
    const userDoc = yield call(getDoc, userRef);

    if (userDoc.exists()) {
      yield put(triggerFetchUserSuccess(userDoc.data() as User));
    } else {
      yield put(triggerFetchUserFailure('User not found in database'));
    }
  } catch (error: any) {
    yield put(triggerFetchUserFailure(error.message || 'Failed to fetch user data'));
  }
}

function* triggerVerifyEmailSaga(
  action: PayloadAction<{
    email: string;
    showNotification: (message: SnackbarMessage, options?: SnackbarOptions) => void;
  }>
): Generator<any, void, void> {
  const { showNotification, email } = action.payload;

  try {
    yield call(axios.post, `${import.meta.env.VITE_BASE_URL}/admin/send-verification-email`, {
      email,
    });

    yield call(showNotification, 'Email sent', {
      variant: 'info',
    });
  } catch (error) {
    console.error(error);

    yield call(showNotification, 'Error sending email', {
      variant: 'error',
    });
  }
}

export function* watchUserSaga() {
  yield takeLatest(triggerFetchUserRequest.type, triggerFetchUserSaga);
  yield takeLatest(triggerVerifyEmailRequest.type, triggerVerifyEmailSaga);
}
