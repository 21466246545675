/* eslint-disable */
import { flush, identify, Identify, setUserId, track } from '@amplitude/analytics-browser';
import { EVENT_DESTINATION } from 'src/enums/EventDestination';
import { EVENT } from 'src/enums/Events';
import { UserProperties } from 'src/enums/UserProperties';

class AnalyticsService {
  private isSuperAdmin(userRole: string): boolean {
    return userRole === 'Superadmin';
  }

  trackEvent(
    eventName: EVENT,
    eventProperties?: Record<string, any>,
    userRole?: string,
    destination: EVENT_DESTINATION = EVENT_DESTINATION.ALL
  ): void {
    if (userRole && this.isSuperAdmin(userRole)) {
      return;
    }

    switch (destination) {
      case EVENT_DESTINATION.AMPLITUDE:
        track(eventName, eventProperties);
        break;

      default:
        track(eventName, eventProperties);
        break;
    }
  }

  identifyUser(
    userId: string | null,
    userProperties?: UserProperties,
    userRole?: string,
    destination: EVENT_DESTINATION = EVENT_DESTINATION.ALL
  ): void {
    if (userRole && this.isSuperAdmin(userRole)) {
      return;
    }

    const idToSet = userId ?? '';

    switch (destination) {
      case EVENT_DESTINATION.AMPLITUDE:
        setUserId(idToSet);
        if (userProperties) {
          const identifyObj = new Identify();
          for (const [key, value] of Object.entries(userProperties)) {
            identifyObj.set(key, value);
          }
          identify(identifyObj);
        }
        break;

      default:
        setUserId(idToSet);
        if (userProperties) {
          const identifyObj = new Identify();
          for (const [key, value] of Object.entries(userProperties)) {
            identifyObj.set(key, value);
          }
          identify(identifyObj);
        }
        break;
    }
  }

  flush(): void {
    flush();
  }
}

export const analyticsService = new AnalyticsService();
