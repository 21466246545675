import type { ButtonProps } from '@mui/material/Button';
import type { Theme, SxProps } from '@mui/material/styles';

import { useCallback } from 'react';

import Button from '@mui/material/Button';

import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';

import { CONFIG } from 'src/config-global';

import { useAuthContext } from 'src/auth/hooks/use-auth-context';
import { signOut as firebaseSignOut } from 'src/auth/context/firebase/action';

// ----------------------------------------------------------------------

type Props = ButtonProps & {
  sx?: SxProps<Theme>;
  onClose?: () => void;
};

const signOut = CONFIG.auth.method === 'firebase' && firebaseSignOut;
export function SignOutButton({ onClose, ...other }: Props) {
  const router = useRouter();
  const { checkUserSession } = useAuthContext();

  const handleLogout = useCallback(async () => {
    try {
      if (signOut) {
        await signOut();
      }
      await checkUserSession?.();

      onClose?.();
      if (window.location.pathname.includes('auth')) {
        router.push(paths.auth.firebase.signIn);
      }
      router.refresh();
    } catch (error) {
      console.error('Error during sign out:', error);
    }
  }, [checkUserSession, onClose, router]);

  return (
    <Button fullWidth variant="soft" size="large" color="error" onClick={handleLogout} {...other}>
      Log out
    </Button>
  );
}
