import axios from 'axios';
import { put, call, takeLatest } from 'redux-saga/effects';

import { getFirebaseToken } from './changeToReadySaga';
import {
  editRequestReject,
  createEditRequest,
  editRequestApprove,
  editRequestSuccess,
  editRequestFailure,
  editRequestSendEmail,
} from '../reducers/editRequestSlice';

function* createEditRequestSaga(
  action: ReturnType<typeof createEditRequest>
): Generator<any, void, any> {
  const idToken = yield call(getFirebaseToken);

  const { showNotification, contestId, brief, rules } = action.payload;

  try {
    const response = yield call(
      axios.post,
      `${import.meta.env.VITE_BASE_URL}/brand-admin/create-edit-contest-request`,
      {
        contestId,
        brief,
        rules,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
      }
    );

    yield put(editRequestSuccess(response.data));
    yield call(showNotification, 'Edit request created');
  } catch (error: any) {
    yield put(editRequestFailure(error.message || 'Failed to edit request'));
    yield call(showNotification, 'Error creating edit request', {
      variant: 'error',
    });
  }
}

function* approveEditRequestSaga(
  action: ReturnType<typeof editRequestApprove>
): Generator<any, void, any> {
  const idToken = yield call(getFirebaseToken);

  const { requestId, comment, fetchSubmissionEditRequest, showNotification } = action.payload;

  try {
    const response = yield call(
      axios.post,
      `${import.meta.env.VITE_BASE_URL}/super-admin/approve-edit-contest-request`,
      {
        requestId,
        comment,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
      }
    );

    yield put(editRequestSuccess(response.data));

    yield call(fetchSubmissionEditRequest);
    yield call(showNotification, 'Edit request approved');
  } catch (error: any) {
    yield put(editRequestFailure(error.message || 'Failed to approve edit request'));
    yield call(showNotification, 'Error approving edit request', {
      variant: 'error',
    });
  }
}

function* rejectEditRequestSaga(
  action: ReturnType<typeof editRequestReject>
): Generator<any, void, any> {
  const idToken = yield call(getFirebaseToken);

  const { requestId, fetchSubmissionEditRequest, rejectReason, comment } = action.payload;

  try {
    const response = yield call(
      axios.post,
      `${import.meta.env.VITE_BASE_URL}/super-admin/reject-edit-contest-request`,
      {
        requestId,
        rejectReason,
        comment,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
      }
    );

    yield put(editRequestSuccess(response.data));

    yield call(fetchSubmissionEditRequest);
    yield call(action.payload.showNotification, 'Edit request rejected');
  } catch (error: any) {
    yield put(editRequestFailure(error.message || 'Failed to reject edit request'));
    yield call(action.payload.showNotification, 'Error rejecting edit request', {
      variant: 'error',
    });
  }
}

function* editRequestSendEmailSaga(
  action: ReturnType<typeof editRequestSendEmail>
): Generator<any, void, any> {
  const idToken = yield call(getFirebaseToken);

  const { requestId, comment, showNotification } = action.payload;

  try {
    const response = yield call(
      axios.post,
      `${import.meta.env.VITE_BASE_URL}/super-admin/reject-edit-contest-request-email`,
      {
        requestId,
        comment,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
      }
    );

    yield put(editRequestSuccess(response.data));
    yield call(showNotification, 'Email sent');
  } catch (error: any) {
    yield put(editRequestFailure(error.message || 'Failed to send email'));
    yield call(showNotification, 'Error sending email', {
      variant: 'error',
    });
  }
}

export function* watchEditRequestSaga() {
  yield takeLatest(createEditRequest.type, createEditRequestSaga);
  yield takeLatest(editRequestSendEmail.type, editRequestSendEmailSaga);
  yield takeLatest(editRequestApprove.type, approveEditRequestSaga);
  yield takeLatest(editRequestReject.type, rejectEditRequestSaga);
}
