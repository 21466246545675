export enum EVENT {
  PAGE_VIEW = 'page_view',
  SIGN_IN = 'sign_in',
  GET_STARTED = 'get_started',
  CREATE_ACCOUNT = 'create_account',
  CREATE_ACCOUNT_ERROR = 'create_account_error',
  AC_CLICK = 'ac_click',
  BRAND_PROFILE_CREATED = 'brand_profile_created',
  PAYWALL_PAGE_VIEW = 'paywall_page_view',
  BOOK_A_CALL = 'book_a_call',
  PURCHASE_STARTED = 'purchase_started',
  NEW_CONTEST_FLOW_STARTED = 'new_contest_flow_started',
  CREATE_CONTEST_PAGE_VIEW = 'create_contest_page_view',
  PURCHASE_COMPLETED = 'purchase_completed',
  PURCHASE_FAILED = 'purchase_failed',
}
