import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

export type CreateContestForm = {
  activeStep: number;
  isBlocked: boolean;
  isLoading: boolean;
  isDraftBlocked: boolean;
};

const initialState: CreateContestForm = {
  activeStep: 0,
  isBlocked: false,
  isLoading: false,
  isDraftBlocked: true,
};

export const createNewContestSlice = createSlice({
  name: 'createNewContest',
  initialState,
  reducers: {
    setActiveStep: (
      state,
      action: PayloadAction<{
        activeStep: number;
      }>
    ) => {
      state.activeStep = action.payload.activeStep;
    },
    setIsBlocked: (
      state,
      action: PayloadAction<{
        isBlocked: boolean;
      }>
    ) => {
      state.isBlocked = action.payload.isBlocked;
    },
    setIsLoading: (
      state,
      action: PayloadAction<{
        isLoading: boolean;
      }>
    ) => {
      state.isLoading = action.payload.isLoading;
    },
    setIsDraftBlocked: (
      state,
      action: PayloadAction<{
        isDraftBlocked: boolean;
      }>
    ) => {
      state.isDraftBlocked = action.payload.isDraftBlocked;
    },
  },
});

export const { setActiveStep, setIsBlocked, setIsLoading, setIsDraftBlocked } =
  createNewContestSlice.actions;
export default createNewContestSlice.reducer;
