import type { AxiosResponse } from 'axios';

import axios from 'axios';
import { put, call, takeLatest } from 'redux-saga/effects';

import { getFirebaseToken } from './changeToReadySaga';
import {
  getSubscriptionSuccess,
  getSubscriptionFailure,
  getBillingPortalReqest,
  getBillingPortalSuccess,
  getBillingPortalFailure,
  cancelSubscriptionRequest,
  cancelSubscriptionSuccess,
  checkSubscriptionStatusRequest,
  checkSubscriptionStatusSuccess,
  checkSubscriptionStatusFailure,
  createSubscriptionCheckoutSessionSuccess,
  createSubscriptionCheckoutSessionFailure,
  createSubscriptionCheckoutSessionRequest,
} from '../reducers/manageSubscriptions';

function* checkSubscriptionStatusSaga(
  action: ReturnType<typeof checkSubscriptionStatusRequest>
): Generator<any, void, AxiosResponse> {
  const idToken = yield call(getFirebaseToken);
  try {
    if (!action.payload || !action.payload.subscriptionId) {
      throw new Error('Subscription ID is required');
    }

    const response = yield call(
      axios.get,
      `${import.meta.env.VITE_BASE_URL}/payment/check-subscription-status`,
      {
        params: {
          subscriptionId: action.payload.subscriptionId,
        },
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      }
    );

    yield put(checkSubscriptionStatusSuccess(response.data));
  } catch (error: any) {
    yield put(
      checkSubscriptionStatusFailure(error.message || 'Failed to check subscription status')
    );
  }
}

function* cancelSubscriptionSaga(
  action: ReturnType<typeof cancelSubscriptionRequest>
): Generator<any, void, AxiosResponse> {
  const idToken = yield call(getFirebaseToken);
  try {
    if (!action.payload || !action.payload.subscriptionId) {
      throw new Error('Subscription ID is required');
    }

    const response = yield call(
      axios.post,
      `${import.meta.env.VITE_BASE_URL}/payment/cancel-subscription`,
      {
        subscriptionId: action.payload.subscriptionId,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
      }
    );

    yield put(cancelSubscriptionSuccess(response.data));
  } catch (error: any) {
    yield put(checkSubscriptionStatusFailure(error.message || 'Failed to cancel subscription'));
  }
}

function* createSubscriptionCheckoutSessionSaga(
  action: ReturnType<typeof createSubscriptionCheckoutSessionRequest>
): Generator<any, void, AxiosResponse> {
  const idToken = yield call(getFirebaseToken);
  try {
    if (!action.payload || !action.payload.brandId) {
      throw new Error('Brand ID is required');
    }

    const response = yield call(
      axios.post,
      `${import.meta.env.VITE_BASE_URL}/payment/create-subscription-checkout-session`,
      {
        brandId: action.payload.brandId,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
      }
    );

    if (response.data.url) {
      window.location.href = response.data.url;
    } else {
      throw new Error('No checkout URL provided');
    }

    yield put(createSubscriptionCheckoutSessionSuccess(response.data));
  } catch (error: any) {
    yield put(
      createSubscriptionCheckoutSessionFailure(error.message || 'Failed to create checkout session')
    );
  }
}

function* getAllSubscriptions(
  action: ReturnType<typeof createSubscriptionCheckoutSessionRequest>
): Generator<any, void, AxiosResponse> {
  const idToken = yield call(getFirebaseToken);
  try {
    if (!action.payload || !action.payload.brandId) {
      throw new Error('Brand ID is required');
    }

    const response = yield call(
      axios.post,
      `${import.meta.env.VITE_BASE_URL}/payment/create-subscription-checkout-session`,
      {
        brandId: action.payload.brandId,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
      }
    );

    if (response.data.url) {
      window.location.href = response.data.url;
    } else {
      throw new Error('No checkout URL provided');
    }

    yield put(getSubscriptionSuccess(response.data));
  } catch (error: any) {
    yield put(getSubscriptionFailure(error.message || 'Failed to create checkout session'));
  }
}

function* getBillingPortalRequestSaga(): Generator<any, void, AxiosResponse> {
  const idToken = yield call(getFirebaseToken);
  try {
    const result = yield call(
      axios.get,
      `${import.meta.env.VITE_BASE_URL}/brand-admin/billing-portal`,
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      }
    );

    yield put(
      getBillingPortalSuccess({
        url: result.data,
      })
    );
  } catch (error: any) {
    yield put(getBillingPortalFailure(error.message || 'Failed to get stripe paywall'));
  }
}

export function* watchSubscriptionSaga() {
  yield takeLatest(checkSubscriptionStatusRequest.type, checkSubscriptionStatusSaga);
  yield takeLatest(cancelSubscriptionRequest.type, cancelSubscriptionSaga);
  yield takeLatest(
    createSubscriptionCheckoutSessionRequest.type,
    createSubscriptionCheckoutSessionSaga
  );
  yield takeLatest(getBillingPortalReqest.type, getBillingPortalRequestSaga);
  yield takeLatest(getSubscriptionSuccess.type, getAllSubscriptions);
}
