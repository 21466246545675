import type { SnackbarMessage } from 'notistack';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { SnackbarOptions } from 'src/components/shared/notifications/notification-snackbar';

import axios from 'axios';
import { call, takeLatest } from 'redux-saga/effects';

import { resetPasswordRequest } from '../reducers/resetPasswordSlice';

function* resetPasswordRequestSaga(
  action: PayloadAction<{
    email: string;
    showNotification: (message: SnackbarMessage, options?: SnackbarOptions) => void;
  }>
): Generator<any, void, any> {
  const { email, showNotification } = action.payload;
  try {
    yield call(axios.post, `${import.meta.env.VITE_BASE_URL}/admin/send-forgot-password-email`, {
      email,
    });

    showNotification(`Password reset email sent to ${email}. Please check your inbox.`, {
      variant: 'info',
      persist: true,
    });
  } catch (error) {
    showNotification(error.response.data.message || 'Failed to reset password', {
      variant: 'error',
      persist: true,
    });
    console.error(error);
  }
}

export function* watchResetPasswordSaga() {
  yield takeLatest(resetPasswordRequest.type, resetPasswordRequestSaga);
}
