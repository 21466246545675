import { all } from 'redux-saga/effects';
import createSagaMiddleware from 'redux-saga';
import { configureStore } from '@reduxjs/toolkit';

import userReducer from './reducers/userSlice';
import brandReducer from './reducers/brandSlice';
import { watchUserSaga } from './sagas/userSaga';
import paymentReducer from './reducers/paymentSlice';
import contestReducer from './reducers/contestSlice';
import portfolioReducer from './reducers/portfolioSlice';
import selectedUserReducer from './reducers/selectedUser';
import { watchPaymentSaga } from './sagas/paymentReducer';
import createLeadReducer from './reducers/createLeadSlice';
import { watchCreateLeadSaga } from './sagas/createLeadSaga';
import editRequestReducer from './reducers/editRequestSlice';
import { watchEditRequestSaga } from './sagas/editRequestSaga';
import subscriptionReducer from './reducers/manageSubscriptions';
import selectedBrandReducer from './reducers/selectedBrandSlice';
import { watchSubscriptionSaga } from './sagas/subscriptionSaga';
import resetPasswordReducer from './reducers/resetPasswordSlice';
import { watchDeletePortfolioSaga } from './sagas/portfolioSaga';
import reportSubmissionReducer from './reducers/reportSubmission';
import selectedStatusReducer from './reducers/changeToReadySlice';
import removeSubmissionReducer from './reducers/removeSubmission';
import triggerContestReducer from './reducers/triggerContestSlice';
import userManagementReducer from './reducers/userManagementSlice';
import { watchContestStatusSaga } from './sagas/changeToReadySaga';
import keepSubmissionReducer from './reducers/keepSubmissionSlice';
import { watchResetPasswordSaga } from './sagas/resetPasswordSaga';
import { watchUserManagementSaga } from './sagas/userManagementSaga';
import { watchKeepSubmissionSaga } from './sagas/keepSubmissionSaga';
import selectedSubmissionReducer from './reducers/selectedSubmission';
import createNewContestReducer from './reducers/createNewContestSlice';
import { watchReportSubmissionSaga } from './sagas/reportSubmissionSaga';
import { watchRemoveSubmissionSaga } from './sagas/removeSubmissionSaga';

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: {
    brand: brandReducer,
    triggerContest: triggerContestReducer,
    userManagement: userManagementReducer,
    user: userReducer,
    contest: contestReducer,
    createNewContest: createNewContestReducer,
    subscription: subscriptionReducer,
    selectedUser: selectedUserReducer,
    selectedBrand: selectedBrandReducer,
    changeStatus: selectedStatusReducer,
    contestPayment: paymentReducer,
    reportSubmission: reportSubmissionReducer,
    selectedSubmission: selectedSubmissionReducer,
    createLead: createLeadReducer,
    keepSubmission: keepSubmissionReducer,
    removeSubmission: removeSubmissionReducer,
    editRequest: editRequestReducer,
    resetPassword: resetPasswordReducer,
    portfolio: portfolioReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: false,
      serializableCheck: false,
    }).concat(sagaMiddleware),
});

function* rootSaga() {
  yield all([
    watchUserManagementSaga(),
    watchSubscriptionSaga(),
    watchContestStatusSaga(),
    watchUserSaga(),
    watchPaymentSaga(),
    watchReportSubmissionSaga(),
    watchCreateLeadSaga(),
    watchKeepSubmissionSaga(),
    watchRemoveSubmissionSaga(),
    watchEditRequestSaga(),
    watchResetPasswordSaga(),
    watchDeletePortfolioSaga(),
  ]);
}

sagaMiddleware.run(rootSaga);

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
