import { paths } from 'src/routes/paths';

import { CONFIG } from 'src/config-global';

import { SvgColor } from 'src/components/svg-color';

const icon = (name: string) => (
  <SvgColor src={`${CONFIG.assetsDir}/assets/icons/navbar/${name}.svg`} />
);

const ICONS = {
  job: icon('ic-job'),
  blog: icon('ic-blog'),
  chat: icon('ic-chat'),
  mail: icon('ic-mail'),
  user: icon('ic-user'),
  file: icon('ic-file'),
  lock: icon('ic-lock'),
  tour: icon('ic-tour'),
  order: icon('ic-order'),
  label: icon('ic-label'),
  blank: icon('ic-blank'),
  kanban: icon('ic-kanban'),
  folder: icon('ic-folder'),
  course: icon('ic-course'),
  banking: icon('ic-banking'),
  booking: icon('ic-booking'),
  invoice: icon('ic-invoice'),
  product: icon('ic-product'),
  calendar: icon('ic-calendar'),
  disabled: icon('ic-disabled'),
  external: icon('ic-external'),
  menuItem: icon('ic-menu-item'),
  ecommerce: icon('ic-ecommerce'),
  analytics: icon('ic-analytics'),
  dashboard: icon('ic-dashboard'),
  parameter: icon('ic-parameter'),
  gear: icon('ic-gear'),
  sidebar: icon('ic-siderbar-duotone'),
};

export const navData = [
  {
    subheader: 'Overview',
    items: [
      {
        title: 'Contests',
        path: paths.dashboard.viewAllContests,
        icon: ICONS.label,
      },
      {
        title: 'User Management',
        path: paths.dashboard.userManagement,
        icon: ICONS.user,
      },
      {
        title: 'Brands',
        path: paths.dashboard.brands,
        icon: ICONS.sidebar,
      },
      {
        title: 'Task Management',
        path: paths.dashboard.tasks,
        icon: ICONS.menuItem,
        children: [
          {
            title: 'Reported Submissions',
            path: paths.dashboard.reportedSubmissions,
          },
          {
            title: 'Pending Approval',
            path: paths.dashboard.pendingApproval,
          },
          {
            title: 'Edit Requests',
            path: paths.dashboard.editRequests,
          },
          {
            title: 'Pending Verification',
            path: paths.dashboard.pendingVerification,
          },
          {
            title: 'Shared Feedback',
            path: paths.dashboard.sharedFeedback,
          },
        ],
      },
      {
        title: 'Account Settings',
        path: paths.dashboard.settings,
        icon: ICONS.gear,
      },
    ],
  },
];
