import type { User } from 'firebase/auth';
import type { DocumentReference } from 'firebase/firestore';

import axios from 'axios';
import { FirebaseError } from 'firebase/app';
import { doc, setDoc, getDoc, collection } from 'firebase/firestore';
import {
  signOut as _signOut,
  sendPasswordResetEmail as _sendPasswordResetEmail,
  signInWithEmailAndPassword as _signInWithEmailAndPassword,
  createUserWithEmailAndPassword as _createUserWithEmailAndPassword,
} from 'firebase/auth';

import { AUTH, FIRESTORE } from 'src/lib/firebase';

// ----------------------------------------------------------------------

export type SignInParams = {
  email: string;
  password: string;
};

export type SignUpParams = {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
};

export type ForgotPasswordParams = {
  email: string;
};

/** **************************************
 * Sign in
 *************************************** */
export const signInWithPassword = async ({
  email,
  password,
}: SignInParams): Promise<{ role: string; brand: DocumentReference | undefined }> => {
  const allowedRoles = ['Brand Admin', 'Superadmin'];

  try {
    await _signInWithEmailAndPassword(AUTH, email, password);

    const user = AUTH.currentUser;

    if (!user) {
      throw new Error('Email not verified!');
    }

    const userProfileRef = doc(FIRESTORE, 'users', user.uid);
    const userProfileSnap = await getDoc(userProfileRef);

    if (!userProfileSnap.exists()) {
      throw new Error('User profile not found');
    }

    const userProfile = userProfileSnap.data();

    if (!allowedRoles.includes(userProfile.role)) {
      throw new Error('Access denied.');
    }

    const { getIdToken } = user;
    axios.defaults.headers.common.Authorization = `Bearer ${getIdToken}`;

    return { role: userProfile.role, brand: userProfile.brand };
  } catch (error) {
    if (error instanceof FirebaseError) {
      switch (error.code) {
        case 'auth/invalid-credential':
          throw new Error('Invalid credentials. Please try again.');
        case 'auth/too-many-requests':
          throw new Error('Too many attempts. Please try again later.');
        default:
          throw new Error('Failed to sign in. Please try again.');
      }
    } else {
      console.error('Error during sign-in:', error);
      throw new Error('An unexpected error occurred during sign-in.');
    }
  }
};

/** **************************************
 * Sign up
 *************************************** */
export const signUp = async ({
  email,
  password,
  firstName,
  lastName,
}: SignUpParams): Promise<User> => {
  try {
    const newUser = await _createUserWithEmailAndPassword(AUTH, email, password);
    const { user } = newUser;

    const userProfileRef = doc(collection(FIRESTORE, 'users'), user.uid);

    await setDoc(userProfileRef, {
      uid: newUser.user?.uid,
      email,
      display_name: firstName,
      last_name: lastName,
      role: 'Brand Admin',
      user_status: 'Active',
    });

    return user;
  } catch (error) {
    if (error instanceof FirebaseError) {
      switch (error.code) {
        case 'auth/email-already-in-use':
          throw new Error('This email is already registered.');
        case 'auth/weak-password':
          throw new Error('Password is too weak. Please choose a stronger one.');
        case 'auth/invalid-email':
          throw new Error('The email address is invalid.');
        default:
          throw new Error('Failed to sign up. Please try again.');
      }
    } else {
      console.error('Error during sign-up:', error);
      throw new Error('An unexpected error occurred during sign-up.');
    }
  }
};

/** **************************************
 * Sign out
 *************************************** */
export const signOut = async (): Promise<void> => {
  await _signOut(AUTH);
  delete axios.defaults.headers.common.Authorization;
};

/** **************************************
 * Reset password
 *************************************** */
export const sendPasswordResetEmail = async ({ email }: ForgotPasswordParams): Promise<void> => {
  await _sendPasswordResetEmail(AUTH, email);
};
