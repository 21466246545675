import type { PayloadAction } from '@reduxjs/toolkit';

import { createSlice } from '@reduxjs/toolkit';

export type PaymentState = {
  loading: boolean;
  error: string | null;
  contestId: string | null;
  amount: number | null;
  productInfo: any | null;
};

const initialState: PaymentState = {
  loading: false,
  error: null,
  contestId: null,
  amount: null,
  productInfo: null,
};

export const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    paymentRequest: (
      state,
      action: PayloadAction<{
        amount: number;
        contestId: string;
      }>
    ) => {
      state.loading = true;
      state.error = null;
    },
    paymentSuccess: (state, action) => {
      state.loading = false;
      state.amount = action.payload;
    },
    paymentFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    paywallInfoRequest: (
      state,
      action: PayloadAction<{
        productId: string;
      }>
    ) => {
      state.loading = true;
      state.error = null;
    },
    paywallInfoSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.productInfo = action.payload;
    },
    paywallInfoFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  paymentRequest,
  paymentSuccess,
  paymentFailure,
  paywallInfoRequest,
  paywallInfoSuccess,
  paywallInfoFailure,
} = paymentSlice.actions;

export default paymentSlice.reducer;
