import type { SnackbarMessage } from 'notistack';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { SnackbarOptions } from 'src/components/shared/notifications/notification-snackbar';

import { createSlice } from '@reduxjs/toolkit';

export type EditRequestState = {
  loading: boolean;
  error: string | null;
};

const initialState: EditRequestState = {
  loading: false,
  error: null,
};

export const editRequestSlice = createSlice({
  name: 'edit-request',
  initialState,
  reducers: {
    createEditRequest: (
      state,
      action: PayloadAction<{
        contestId: string;
        brief: string;
        rules: string;
        showNotification: (message: SnackbarMessage, options?: SnackbarOptions) => void;
      }>
    ) => {
      state.loading = true;
      state.error = null;
    },
    editRequestSuccess: (state) => {
      state.loading = false;
    },
    editRequestFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    editRequestSendEmail: (
      state,
      action: PayloadAction<{
        requestId: string;
        comment: string;
        showNotification: (message: SnackbarMessage, options?: SnackbarOptions) => void;
      }>
    ) => {
      state.loading = true;
      state.error = null;
    },
    editRequestApprove: (
      state,
      action: PayloadAction<{
        requestId: string;
        comment: string;
        fetchSubmissionEditRequest: () => Promise<void>;
        showNotification: (message: SnackbarMessage, options?: SnackbarOptions) => void;
      }>
    ) => {
      state.loading = true;
      state.error = null;
    },
    editRequestReject: (
      state,
      action: PayloadAction<{
        requestId: string;
        rejectReason: string;
        comment: string;
        fetchSubmissionEditRequest: () => Promise<void>;
        showNotification: (message: SnackbarMessage, options?: SnackbarOptions) => void;
      }>
    ) => {
      state.loading = true;
      state.error = null;
    },
  },
});

export const {
  createEditRequest,
  editRequestSuccess,
  editRequestReject,
  editRequestApprove,

  editRequestFailure,
  editRequestSendEmail,
} = editRequestSlice.actions;

export default editRequestSlice.reducer;
