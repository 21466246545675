import { Box, Link, Typography, Breadcrumbs } from '@mui/material';

import { useRouter, usePathname } from 'src/routes/hooks';

import { CONFIG } from 'src/config-global';
import { type DashboardNode } from 'src/interfaces/dashboard-node';

import { SvgColor } from 'src/components/svg-color';

import { useNavBreadcrumbs } from './useNavBreadCrumbs';

// ----------------------------------------------------------------------

export function NavBreadcrumbs({ dashboardRoutes }: { dashboardRoutes: DashboardNode[] }) {
  const pathName = usePathname();

  const breadCrumbList = useNavBreadcrumbs(pathName, dashboardRoutes);

  return (
    <Box display="flex" alignItems="center" gap={1}>
      <Breadcrumbs
        separator={
          <SvgColor
            color={(theme) => theme.palette.grey[600]}
            src={`${CONFIG.assetsDir}/assets/icons/custom/NextButton.svg`}
            width={16}
          />
        }
        aria-label="breadcrumb"
      >
        {breadCrumbList.map((item, index) => (
          <Crumb key={index} item={item} isLast={index === breadCrumbList.length - 1} />
        ))}
      </Breadcrumbs>
    </Box>
  );
}

const Crumb = ({ item, isLast = false }: { item: DashboardNode; isLast?: boolean }) => {
  const router = useRouter();

  return item.unclickable || isLast ? (
    <Box key={item.label}>
      <Typography color={(theme) => theme.palette.text.disabled} variant="body1">
        {item.label}
      </Typography>
    </Box>
  ) : (
    <Link
      key={item.label}
      color={isLast ? 'primary' : 'inherit'}
      onClick={() => {
        router.push(item.href!);
      }}
      sx={{
        cursor: 'pointer',
      }}
    >
      <Typography variant="body1">{item.label}</Typography>
    </Link>
  );
};
