import type { SnackbarMessage } from 'notistack';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { SnackbarOptions } from 'src/components/shared/notifications/notification-snackbar';

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  error: null,
  success: false,
};

export const resetPasswordSlice = createSlice({
  name: 'resetPassword',
  initialState,
  reducers: {
    resetPasswordRequest: (
      state,
      action: PayloadAction<{
        email: string;
        showNotification: (message: SnackbarMessage, options?: SnackbarOptions) => void;
      }>
    ) => {
      state.loading = true;
      state.error = null;
    },
    resetPasswordSuccess: (state, action) => {
      state.loading = false;
      state.success = true;
    },
    resetPasswordFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { resetPasswordRequest, resetPasswordSuccess, resetPasswordFailure } =
  resetPasswordSlice.actions;

export default resetPasswordSlice.reducer;
