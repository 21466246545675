import axios from 'axios';
import { put, call, takeLatest } from 'redux-saga/effects';

import { getFirebaseToken } from './changeToReadySaga';
import {
  getPortfolioIdRequest,
  getPortfolioIdFailure,
  getPortfolioIdSuccess,
} from '../reducers/portfolioSlice';

function* deletePortfolioSaga(
  action: ReturnType<typeof getPortfolioIdRequest>
): Generator<any, void, any> {
  const idToken = yield call(getFirebaseToken);
  try {
    const response = yield call(
      axios.post,
      `${import.meta.env.VITE_BASE_URL}/super-admin/delete-portfolio-video`,
      {
        portfolioId: action.payload.portfolioId,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
      }
    );

    yield put(getPortfolioIdSuccess(response.data));
  } catch (error: any) {
    yield put(getPortfolioIdFailure(error.message || 'Failed to delete portfolio'));
  }
}

export function* watchDeletePortfolioSaga() {
  yield takeLatest(getPortfolioIdRequest, deletePortfolioSaga);
}
