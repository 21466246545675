import type { PayloadAction } from '@reduxjs/toolkit';

import { createSlice } from '@reduxjs/toolkit';

export type PortfolioState = {
  portfolioId: string | null;
  loading: boolean;
  error: string | null;
};

const initialState: PortfolioState = {
  portfolioId: null,
  loading: false,
  error: null,
};

export const portfolioSlice = createSlice({
  name: 'portfolio',
  initialState,
  reducers: {
    getPortfolioIdRequest: (state, action: PayloadAction<PortfolioState>) => {
      state.loading = true;
      state.error = null;
    },
    getPortfolioIdSuccess: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.portfolioId = action.payload;
    },
    getPortfolioIdFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { getPortfolioIdRequest, getPortfolioIdSuccess, getPortfolioIdFailure } =
  portfolioSlice.actions;

export default portfolioSlice.reducer;
