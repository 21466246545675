import type { ReactNode } from 'react';
import type { DashboardNode } from 'src/interfaces/dashboard-node';

import { Outlet } from 'react-router-dom';
import { lazy, Suspense, useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';

import { useUser } from 'src/hooks/use-user';
import { useBrand } from 'src/hooks/use-brand';

import { AUTH } from 'src/lib/firebase';
import { UserRoles } from 'src/enums/Roles';
import { DashboardLayout } from 'src/layouts/dashboard';
import { fetchBrandData } from 'src/services/fetch-brand';

import { LoadingScreen } from 'src/components/loading-screen';

import { AuthGuard, RoleBasedGuard } from 'src/auth/guard';

import { paths } from '../paths';

const ContestsPage = lazy(() => import('src/pages/dashboard/contests'));
const MyBrandPage = lazy(() => import('src/pages/dashboard/my-brand'));
const AccountSettingsPage = lazy(() => import('src/pages/dashboard/account-settings'));
const PayoutsPage = lazy(() => import('src/pages/dashboard/payouts'));
const UserManagementPage = lazy(() => import('src/pages/super-admin/user-management'));
const ContestDetailsPage = lazy(() => import('src/pages/dashboard/contests/contest-details'));
const NewCreateContestView = lazy(
  () => import('src/pages/dashboard/contests/new-create-new-contest')
);
const ViewAllContests = lazy(() => import('src/pages/super-admin/all-contests'));
const EditUserPage = lazy(() => import('src/pages/super-admin/edit-user'));
const BrandPage = lazy(() => import('src/pages/super-admin/brands'));
const BrandManagementPage = lazy(() => import('src/pages/super-admin/brand-management'));
const PendingApprovalPage = lazy(() => import('src/pages/super-admin/tasks/pending-approval'));
const EditRequestsPage = lazy(() => import('src/pages/super-admin/tasks/edit-requests'));
const EditRequestHistoryPage = lazy(
  () => import('src/pages/super-admin/tasks/edit-request-history')
);
const PendingVerificationPage = lazy(
  () => import('src/pages/super-admin/tasks/pending-verification')
);
const ReportedSubmissionsPage = lazy(
  () => import('src/pages/super-admin/tasks/reported-submissions')
);
const SharedFeedbackPage = lazy(() => import('src/pages/super-admin/tasks/shared-feedback'));

const PaymentFailedNotification = lazy(() => import('src/sections/redirects/payment-failed'));
const PaymentSuccessNotification = lazy(() => import('src/sections/redirects/payment-success'));

export const DashboardRoutesWrapper = () => {
  const { user, fetchUser } = useUser();
  const { brand, setBrand } = useBrand();

  useEffect(() => {
    if (!user) {
      const unsubscribe = onAuthStateChanged(AUTH, (userProfile) => {
        if (userProfile) {
          fetchUser(userProfile.uid);
        } else {
          console.error('User is not logged in');
        }
      });

      return () => unsubscribe();
    }

    return () => {};
  }, [fetchUser, user]);

  const userRole = user?.role || '';

  useEffect(() => {
    const fetchBrand = async () => {
      try {
        const fetchedbrand = await fetchBrandData(brand, user?.brand?.id);

        if (fetchedbrand) {
          setBrand(fetchedbrand);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchBrand();
  });

  return (
    <DashboardLayout dashboardRoutes={dashboardRoutes}>
      <Suspense fallback={<LoadingScreen />}>
        <Outlet context={{ userRole }} />
      </Suspense>
    </DashboardLayout>
  );
};

interface ProtectedRouteProps {
  acceptRoles: UserRoles[];
  children: ReactNode;
}

export const ProtectedRoute = ({ acceptRoles, children }: ProtectedRouteProps) => (
  <RoleBasedGuard acceptRoles={acceptRoles}>{children}</RoleBasedGuard>
);

export const dashboardRoutes: DashboardNode[] = [
  {
    path: 'dashboard',
    label: 'Dashboard',
    href: paths.dashboard.root,
    element: (
      <AuthGuard>
        <DashboardRoutesWrapper />
      </AuthGuard>
    ),
    children: [
      {
        path: 'payouts',
        label: 'Payouts',
        href: paths.dashboard.payouts,
        element: (
          <ProtectedRoute acceptRoles={[UserRoles.SUPERADMIN]}>
            <PayoutsPage />
          </ProtectedRoute>
        ),
      },
      {
        path: 'contests',
        label: 'Contests',
        href: paths.dashboard.root,
        element: (
          <ProtectedRoute acceptRoles={[UserRoles.BRAND_ADMIN]}>
            <ContestsPage />
          </ProtectedRoute>
        ),
      },
      {
        path: 'contest/:contestId',
        label: 'Contest Details',
        href: paths.dashboard.contestDetails,
        element: (
          <ProtectedRoute acceptRoles={[UserRoles.BRAND_ADMIN, UserRoles.SUPERADMIN]}>
            <ContestDetailsPage />
          </ProtectedRoute>
        ),
      },
      {
        path: 'contest/new-create-new-contest',
        label: 'Create New Contest',
        href: paths.dashboard.newCreateContest,
        element: (
          <ProtectedRoute acceptRoles={[UserRoles.BRAND_ADMIN, UserRoles.SUPERADMIN]}>
            <NewCreateContestView />
          </ProtectedRoute>
        ),
      },
      {
        path: 'contest/view-all-contests',
        label: 'View All Contests',
        href: paths.dashboard.viewAllContests,
        element: (
          <ProtectedRoute acceptRoles={[UserRoles.SUPERADMIN]}>
            <ViewAllContests />
          </ProtectedRoute>
        ),
      },
      {
        path: 'my-brand',
        label: 'My Brand',
        href: paths.dashboard.myBrand,
        element: (
          <ProtectedRoute acceptRoles={[UserRoles.BRAND_ADMIN]}>
            <MyBrandPage />
          </ProtectedRoute>
        ),
      },
      {
        path: 'brands',
        label: 'Brands',
        href: paths.dashboard.brands,
        element: (
          <ProtectedRoute acceptRoles={[UserRoles.SUPERADMIN]}>
            <BrandPage />
          </ProtectedRoute>
        ),
      },
      {
        path: 'settings',
        label: 'Account Settings',
        href: paths.dashboard.settings,
        element: <AccountSettingsPage />,
      },
      {
        path: 'user-management',
        label: 'User Management',
        href: paths.dashboard.userManagement,
        element: (
          <ProtectedRoute acceptRoles={[UserRoles.SUPERADMIN]}>
            <UserManagementPage />
          </ProtectedRoute>
        ),
      },
      {
        path: 'user-management/:userId',
        label: 'Edit User',
        href: paths.dashboard.userDetails,
        element: (
          <ProtectedRoute acceptRoles={[UserRoles.SUPERADMIN]}>
            <EditUserPage />
          </ProtectedRoute>
        ),
      },
      {
        path: 'brand-management/:brandId',
        label: 'Brand Details',
        href: paths.dashboard.brandDetails,
        element: (
          <ProtectedRoute acceptRoles={[UserRoles.SUPERADMIN]}>
            <BrandManagementPage />
          </ProtectedRoute>
        ),
      },

      {
        path: 'tasks',
        label: 'Tasks',
        href: paths.dashboard.tasks,
        unclickable: true,
        children: [
          {
            path: 'reported-submissions',
            label: 'Reported Submissions',
            href: paths.dashboard.reportedSubmissions,
            element: (
              <ProtectedRoute acceptRoles={[UserRoles.SUPERADMIN]}>
                <ReportedSubmissionsPage />
              </ProtectedRoute>
            ),
          },
          {
            path: 'pending-approval',
            label: 'Pending Approval',
            href: paths.dashboard.pendingApproval,
            element: (
              <ProtectedRoute acceptRoles={[UserRoles.SUPERADMIN]}>
                <PendingApprovalPage />
              </ProtectedRoute>
            ),
          },

          {
            path: 'edit-requests',
            label: 'Edit Requests',
            href: paths.dashboard.editRequests,
            element: (
              <ProtectedRoute acceptRoles={[UserRoles.SUPERADMIN]}>
                <EditRequestsPage />
              </ProtectedRoute>
            ),
          },

          {
            path: 'edit-request-history/:editRequestId',
            label: 'Edit Request History',
            href: paths.dashboard.editRequestHistory,
            element: (
              <ProtectedRoute acceptRoles={[UserRoles.SUPERADMIN, UserRoles.BRAND_ADMIN]}>
                <EditRequestHistoryPage />
              </ProtectedRoute>
            ),
          },

          {
            path: 'pending-verification',
            label: 'Pending Verification',
            href: paths.dashboard.pendingVerification,
            element: (
              <ProtectedRoute acceptRoles={[UserRoles.SUPERADMIN]}>
                <PendingVerificationPage />
              </ProtectedRoute>
            ),
          },
          {
            path: 'shared-feedback',
            label: 'Shared Feedback',
            href: paths.dashboard.sharedFeedback,

            element: (
              <ProtectedRoute acceptRoles={[UserRoles.SUPERADMIN]}>
                <SharedFeedbackPage />
              </ProtectedRoute>
            ),
          },
        ],
      },
      {
        path: '/dashboard/contests/success',
        element: <PaymentSuccessNotification />,
      },
      {
        path: '/dashboard/contests/cancel',
        element: <PaymentFailedNotification />,
      },
    ],
  },
];
