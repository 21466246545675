import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
// eslint-disable-next-line
import * as amplitude from '@amplitude/analytics-browser';

import App from './app';

// ----------------------------------------------------------------------

// Sentry.init({
//   dsn: 'https://7686082141db28f206d26fa9c70e71c1@o4508257650278400.ingest.us.sentry.io/4508261571362816',
//   integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
//   // Tracing
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//   tracePropagationTargets: ['localhost', CONFIG.serverUrl],
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
//   environment: CONFIG.environment,
// });

amplitude.init(import.meta.env.VITE_AMPLITUDE_API_KEY, {
  autocapture: {
    attribution: true,
    pageViews: false,
    sessions: true,
    formInteractions: false,
    fileDownloads: false,
    elementInteractions: false,
  },
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  // <StrictMode>
  <HelmetProvider>
    <BrowserRouter>
      <Suspense>
        <App />
      </Suspense>
    </BrowserRouter>
  </HelmetProvider>
  // {/* </StrictMode> */}
);
