import { paths } from 'src/routes/paths';

import { type DashboardNode } from 'src/interfaces/dashboard-node';

export const useNavBreadcrumbs = (pathName: string, dashboardRoutes: DashboardNode[]) => {
  function iterateRoutes(routes: DashboardNode[], searchedHref: string) {
    const stack: DashboardNode[] = [];

    const recursivelyFindNode = (node: DashboardNode): boolean => {
      const { children, ...nodeBody } = node;
      stack.push(nodeBody);

      if (node.href === searchedHref) return true;

      if (children) {
        if (children.some((nestedNode) => recursivelyFindNode(nestedNode))) {
          return true;
        }
      }

      stack.pop();
      return false;
    };

    if (routes.some((_, index) => recursivelyFindNode(routes[index]))) {
      return stack;
    }

    return [];
  }

  const croppedPaths = [
    paths.dashboard.brandDetails,
    paths.dashboard.contestDetails,
    paths.dashboard.userDetails,
    paths.dashboard.editRequestHistory,
  ];

  const transformPathToTemplate = (requestedPath: string, routeTemplates: string[]): string => {
    const matchedTemplate = routeTemplates.find((template) => {
      const templateSegments = template.split('/');
      const pathSegments = requestedPath.split('/');

      const exceptionPaths = [
        paths.dashboard.newCreateContest,
        paths.dashboard.viewAllContests,
        paths.dashboard.newCreateContest,
      ];

      if (exceptionPaths.includes(requestedPath)) return false;

      if (templateSegments.length !== pathSegments.length) {
        return false;
      }

      return templateSegments.every(
        (segment, index) => segment.startsWith(':') || segment === pathSegments[index]
      );
    });

    return matchedTemplate || requestedPath;
  };

  const transformedPath = transformPathToTemplate(pathName, croppedPaths);

  const breadCrumbList = iterateRoutes(dashboardRoutes, transformedPath);

  return breadCrumbList;
};
