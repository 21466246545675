import type { User } from 'src/interfaces/user';
import type { SnackbarMessage } from 'notistack';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { SnackbarOptions } from 'src/components/shared/notifications/notification-snackbar';

import { createSlice } from '@reduxjs/toolkit';

export type UserState = {
  loading: boolean;
  error: string | null;
  user: User | null;
};

const initialState: UserState = {
  loading: false,
  error: null,
  user: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User | null>) => {
      state.user = action.payload;
    },
    updateUser: (state, action: PayloadAction<Partial<User>>) => {
      if (state.user) {
        state.user = { ...state.user, ...action.payload };
      }
    },
    triggerFetchUserRequest: (state, action: PayloadAction<string>) => {
      state.loading = true;
      state.error = null;
    },
    triggerFetchUserSuccess: (state, action: PayloadAction<User>) => {
      state.loading = false;
      state.user = action.payload;
    },
    triggerFetchUserFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    triggerVerifyEmailRequest: (
      state,
      action: PayloadAction<{
        email: string;
        showNotification: (message: SnackbarMessage, options?: SnackbarOptions) => void;
      }>
    ) => {},
  },
});

export const {
  setUser,
  updateUser,
  triggerFetchUserRequest,
  triggerFetchUserSuccess,
  triggerFetchUserFailure,
  triggerVerifyEmailRequest,
} = userSlice.actions;

export default userSlice.reducer;
