import type { User } from 'src/interfaces/user';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { UserAggregated } from 'src/interfaces/agregated/all-users';

import { createSlice } from '@reduxjs/toolkit';

export type UserState = {
  loading: boolean;
  error: string | null;
  selectedUser: UserAggregated | User | null;
};

const initialState: UserState = {
  loading: false,
  error: null,
  selectedUser: null,
};

export const userSlice = createSlice({
  name: 'selected-user',
  initialState,
  reducers: {
    setSelectedUser: (state, action: PayloadAction<UserAggregated | User | null>) => {
      state.selectedUser = action.payload;
    },
    updateSelectedUser: (state, action: PayloadAction<Partial<UserAggregated | User>>) => {
      if (state.selectedUser) {
        state.selectedUser = { ...state.selectedUser, ...action.payload } as UserAggregated | User;
      }
    },
  },
});

export const { setSelectedUser, updateSelectedUser } = userSlice.actions;

export default userSlice.reducer;
