import type { Brand } from 'src/interfaces/brand';

import { doc, getDoc } from 'firebase/firestore';

import { createdTime } from 'src/utils/user-manager-time-format';

import { FIRESTORE } from 'src/lib/firebase';

export const fetchBrandData = async (
  brand: Brand | null,
  userBrandId: string | undefined
): Promise<Brand | null> => {
  if (!brand && userBrandId) {
    const docRef = doc(FIRESTORE, 'brands', userBrandId);
    const docSnap = await getDoc(docRef);
    const data = docSnap.data();
    if (data) {
      const parsedCreatedAt = data.created_at;
      const brandData = {
        ...data,
        id: docSnap.id,
        created_at: createdTime(parsedCreatedAt),
      };

      return brandData as Brand;
    }
  }
  return null;
};
