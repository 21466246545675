import type { PayloadAction } from '@reduxjs/toolkit';

import { createSlice } from '@reduxjs/toolkit';

import { SubscriptionStatus } from 'src/enums/SubscriptionStatus';

export type SubscriptionState = {
  loading: boolean;
  error: string | null;
  url: string | null;
  status?: string | null | undefined;
};

const initialState: SubscriptionState = {
  loading: false,
  error: null,
  url: null,
  status: null,
};

export const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    getSubscriptionRequest: (state, action: PayloadAction<{ brandId: string }>) => {
      state.loading = true;
      state.error = null;
    },
    getSubscriptionSuccess: (state) => {
      state.loading = false;
    },
    getSubscriptionFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    getBillingPortalReqest: (state) => {
      state.loading = true;
      state.error = null;
    },
    getBillingPortalSuccess: (
      state,
      action: PayloadAction<{
        url: string;
      }>
    ) => {
      state.loading = false;
      state.url = action.payload.url;
    },
    getBillingPortalFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    checkSubscriptionStatusRequest: (state, action: PayloadAction<{ subscriptionId: string }>) => {
      state.loading = true;
      state.error = null;
    },
    checkSubscriptionStatusSuccess: (state, action: PayloadAction<string>) => {
      state.status = action.payload;
      state.loading = false;
    },
    checkSubscriptionStatusFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    cancelSubscriptionRequest: (state, action: PayloadAction<{ subscriptionId: string }>) => {
      state.loading = true;
      state.error = null;
    },
    cancelSubscriptionSuccess: (state) => {
      state.status = SubscriptionStatus.CANCELED;
      state.loading = false;
    },
    cancelSubscriptionFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    createSubscriptionCheckoutSessionRequest: (
      state,
      action: PayloadAction<{ brandId: string }>
    ) => {
      state.loading = true;
      state.error = null;
    },
    createSubscriptionCheckoutSessionSuccess: (state) => {
      state.loading = false;
    },
    createSubscriptionCheckoutSessionFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  cancelSubscriptionRequest,
  cancelSubscriptionSuccess,
  cancelSubscriptionFailure,
  getSubscriptionRequest,
  getSubscriptionSuccess,
  getSubscriptionFailure,
  getBillingPortalReqest,
  getBillingPortalSuccess,
  getBillingPortalFailure,
  checkSubscriptionStatusRequest,
  checkSubscriptionStatusSuccess,
  checkSubscriptionStatusFailure,
  createSubscriptionCheckoutSessionRequest,
  createSubscriptionCheckoutSessionSuccess,
  createSubscriptionCheckoutSessionFailure,
} = subscriptionSlice.actions;

export default subscriptionSlice.reducer;
