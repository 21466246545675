import type { ReactNode } from 'react';
import type { OptionsObject, SnackbarMessage } from 'notistack';

import { useMemo, useContext, createContext } from 'react';
import { closeSnackbar, enqueueSnackbar, SnackbarProvider } from 'notistack';

import { Button } from '@mui/material';

import { CONFIG } from 'src/config-global';
import COLORS from 'src/theme/core/colors.json';

import { SvgColor } from 'src/components/svg-color';

export type SnackbarOptions =
  | OptionsObject<'success' | 'error' | 'warning' | 'info' | 'default'>
  | undefined;

interface NotificationContextType {
  showNotification: (message: SnackbarMessage, options?: SnackbarOptions) => void;
}

const NotificationContext = createContext<NotificationContextType | undefined>(undefined);

export const NotificationProvider = ({ children }: { children: ReactNode }) => {
  const showNotification = (message: SnackbarMessage, options?: SnackbarOptions) => {
    const key = enqueueSnackbar(message, {
      variant: options?.variant || 'success',
      anchorOrigin: options?.anchorOrigin || {
        vertical: 'bottom',
        horizontal: 'right',
      },
      autoHideDuration: 5000,
      action: () => (
        <Button
          sx={{
            minWidth: 0,
          }}
          onClick={() => {
            closeSnackbar(key);
          }}
        >
          <SvgColor
            src={`${CONFIG.assetsDir}/assets/icons/custom/Close.svg`}
            height={18}
            width={18}
            color={COLORS.grey[200]}
            sx={{ cursor: 'pointer', display: 'flex' }}
          />
        </Button>
      ),
      ...options,
    });
  };

  const memoizedValue = useMemo(() => ({ showNotification }), []);

  return (
    <NotificationContext.Provider value={memoizedValue}>
      <SnackbarProvider>{children}</SnackbarProvider>
    </NotificationContext.Provider>
  );
};

export const useNotification = () => {
  const context = useContext(NotificationContext);
  if (context === undefined) {
    throw new Error('useNotification must be used within a NotificationProvider');
  }
  return context;
};
