import { useLocation } from 'react-router';
import { useRef, useCallback } from 'react';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Alert from '@mui/material/Alert';
import { useTheme, type Theme, type SxProps, type Breakpoint } from '@mui/material/styles';

import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';

import { useUser } from 'src/hooks/use-user';

import { EVENT } from 'src/enums/Events';
import { analyticsService } from 'src/services/analytics-service';

import { Logo } from 'src/components/logo';

import { Main, Content } from './main';
import { _account } from '../config-nav-account';
import { HeaderSection } from '../core/header-section';
import { LayoutSection } from '../core/layout-section';
import { AccountDrawer } from '../components/account-drawer';
import { SettingsButton } from '../components/settings-button';

// ----------------------------------------------------------------------

export type AuthSplitLayoutProps = {
  sx?: SxProps<Theme>;
  children: React.ReactNode;
  header?: {
    sx?: SxProps<Theme>;
  };
  section?: {
    title?: string;
    imgUrl?: string;
    subtitle?: string;
  };
};

export function AuthSplitLayout({ sx, section, children, header }: AuthSplitLayoutProps) {
  const layoutQuery: Breakpoint = 'md';
  const theme = useTheme();
  const { pathname } = useLocation();

  const { user } = useUser();
  const userRole = user?.role;

  const isSubscriptionPage = pathname === paths.auth.firebase.createSubscription;
  const isVerifyEmailPage = pathname === paths.auth.firebase.verifyEmail;
  const isFinishProfilePage = pathname === paths.auth.firebase.finishProfile;

  const startTimeRef = useRef<number>(Date.now());

  const handleBookDemo = useCallback(() => {
    if (isFinishProfilePage) {
      const timeSpent = Math.floor((Date.now() - startTimeRef.current) / 1000);
      analyticsService.trackEvent(
        EVENT.BOOK_A_CALL,
        {
          time_on_page: timeSpent,
        },
        userRole
      );
    }
  }, [isFinishProfilePage, userRole]);

  return (
    <LayoutSection
      headerSection={
        /** **************************************
         * Header
         *************************************** */
        <HeaderSection
          disableElevation
          layoutQuery={layoutQuery}
          slotProps={{ container: { maxWidth: false } }}
          sx={{
            position: { [layoutQuery]: 'fixed' },
            backgroundColor: theme.palette.background.default,
            ...header?.sx,
          }}
          slots={{
            topArea: (
              <Alert severity="info" sx={{ display: 'none', borderRadius: 0 }}>
                This is an info Alert.
              </Alert>
            ),
            leftArea: (
              <>
                {/* -- Logo -- */}
                <Logo href={isSubscriptionPage ? '' : '/'} component="a" />
              </>
            ),
            rightArea: (
              <Box display="flex" alignItems="center" gap={{ xs: 1, sm: 1.5 }}>
                {/* -- Settings button -- */}
                <SettingsButton />
                {/* -- Help link -- */}
                {isSubscriptionPage ? (
                  <AccountDrawer data={_account} />
                ) : (
                  <>
                    <Link
                      href="https://calendly.com/steven-postedapp/posted-app-intro?utm_source=lp"
                      onClick={handleBookDemo}
                      component={RouterLink}
                      color="inherit"
                      sx={{ typography: 'subtitle2' }}
                    >
                      Need help?
                    </Link>

                    {isVerifyEmailPage && <AccountDrawer data={_account} />}
                  </>
                )}
              </Box>
            ),
          }}
        />
      }
      /** **************************************
       * Footer
       *************************************** */
      footerSection={null}
      /** **************************************
       * Style
       *************************************** */
      cssVars={{ '--layout-auth-content-width': '420px' }}
      sx={sx}
    >
      <Main layoutQuery={layoutQuery}>
        <Content layoutQuery={layoutQuery}>{children}</Content>
      </Main>
    </LayoutSection>
  );
}
